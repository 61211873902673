import * as React from "react";
import Collapsible from "react-collapsible";
import { graphql, Link } from "gatsby";
import {
  Avis,
  FormHero,
  Hero,
  Layout,
  ListCheck,
  Separator,
  StickyBar,
  TextImg,
  Title,
  Wrapper,
} from "../components/";
import _ from "lodash";
import styled from "styled-components";
import PlomberieImg from "../assets/domain/plomberie_home.jpg";
import { Helmet } from "react-helmet";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../context/app-context";
import axios from "axios";

export const query = graphql`
  query regions {
    allRegionsJson(
      filter: { code: { nin: ["01", "02", "03", "04", "06", "94", "COM"] } }
    ) {
      edges {
        node {
          slug
          name
          id
          code
        }
      }
    }
    allCitiesJson(
      filter: {
        zip_code: {
          in: [
            "06700"
            "13600"
            "22000"
            "24000"
            "26100"
            "28100"
            "30100"
            "31770"
            "35400"
            "37300"
            "40000"
            "44400"
            "44800"
            "47000"
            "50100"
            "54500"
            "59400"
            "59600"
            "60100"
            "62800"
            "67300"
            "69000"
            "69140"
            "69330"
            "71000"
            "74100"
            "74200"
            "76200"
            "78130"
            "78180"
            "78190"
            "78370"
            "78400"
            "78800"
            "83700"
            "86100"
            "88000"
            "89000"
            "91120"
            "91170"
            "91200"
            "92210"
            "92240"
            "93390"
            "94130"
            "94220"
            "94240"
            "94320"
            "95190"
            "95300"
            "75000"
            "31000"
            "06000"
            "44000"
            "34000"
            "59000"
            "35000"
            "76600"
            "42000"
            "83000"
            "49000"
            "30000"
            "13080"
            "29200"
            "37000"
            "92100"
            "76000"
            "54100"
            "93100"
            "86000"
            "92400"
            "92600"
            "64000"
            "34500"
            "44600"
            "29000"
            "26000"
            "69200"
            "60155"
            "83400"
            "49300"
            "13104"
            "83600"
            "93150"
            "06130"
            "74000"
            "81990"
            "64100"
            "59300"
            "34200"
            "81100"
            "62000"
            "69120"
            "69300"
            "60200"
            "92220"
            "64600"
            "42400"
            "83140"
            "92320"
            "13000"
            "67000"
            "33000"
            "51100"
            "38000"
            "21000"
            "25000"
            "95100"
            "59100"
            "59140"
            "59200"
            "06600"
            "62100"
            "06400"
            "83500"
            "95200"
            "33600"
            "94200"
            "73000"
            "79000"
            "56100"
            "95000"
            "82000"
            "02100"
            "13117"
            "13400"
            "71100"
            "62200"
            "65000"
            "78200"
            "13118"
            "06110"
            "91300"
            "59500"
            "69800"
            "93190"
            "16000"
            "59150"
            "92230"
            "93110"
            "57100"
            "94600"
            "33400"
            "28000"
            "42300"
            "83300"
            "38400"
            "38130"
            "26200"
            "67500"
            "69100"
            "72000"
            "63000"
            "87000"
            "80000"
            "57000"
            "66000"
            "45000"
            "68100"
            "14000"
            "94500"
            "17000"
            "94100"
            "68000"
            "93700"
            "18000"
            "33700"
            "92130"
            "92300"
            "59491"
            "94700"
            "77500"
            "85000"
            "92140"
            "56000"
            "91090"
            "78500"
            "11100"
            "53000"
            "77100"
            "06800"
            "92150"
            "41000"
            "36000"
            "51000"
            "92190"
            "92800"
            "94140"
            "13300"
            "91100"
            "93120"
            "78300"
            "91600"
            "58000"
            "93420"
            "77340"
            "78700"
            "69400"
            "62300"
            "13127"
            "93200"
            "94000"
            "84000"
            "92000"
            "78000"
            "94400"
            "92700"
            "93600"
            "92500"
            "93300"
            "93160"
            "92160"
            "92200"
            "10000"
            "92110"
            "94800"
            "93800"
            "93500"
            "93140"
            "94120"
            "27000"
            "90000"
            "93270"
            "08090"
            "19100"
            "92120"
            "94300"
            "93000"
            "11000"
            "93400"
            "78100"
            "01000"
            "95140"
            "05000"
            "93130"
            "77000"
            "93220"
            "59700"
            "69500"
            "03100"
            "93290"
            "13700"
            "91700"
            "93170"
            "93240"
            "93330"
            "95130"
            "94170"
            "94190"
            "92290"
            "34500"
            "30200"
            "84100"
            "31170"
            "84200"
            "38300"
            "73100"
          ]
        }
      }
    ) {
      edges {
        node {
          code_insee
          department_code
          gps_lat
          gps_lng
          id
          name
          slug
          zip_code
        }
      }
    }
    allDepartmentsJson(
      filter: {
        code: {
          in: [
            "13"
            "67"
            "33"
            "51"
            "38"
            "21"
            "25"
            "95"
            "59"
            "06"
            "62"
            "83"
            "94"
            "73"
            "79"
            "56"
            "82"
            "02"
            "71"
            "65"
            "78"
            "91"
            "69"
            "93"
            "16"
            "92"
            "57"
            "28"
            "42"
            "26"
            "22"
            "24"
            "30"
            "31"
            "35"
            "37"
            "40"
            "44"
            "47"
            "50"
            "54"
            "60"
            "74"
            "76"
            "86"
            "88"
            "89"
            "72"
            "63"
            "87"
            "80"
            "66"
            "45"
            "68"
            "14"
            "17"
            "18"
            "77"
            "85"
            "11"
            "53"
            "41"
            "36"
            "58"
            "84"
            "10"
            "27"
            "90"
            "08"
            "19"
            "01"
            "05"
            "03"
            "34"
            "75"
            "29"
          ]
        }
      }
    ) {
      edges {
        node {
          code
          id
          name
          region_code
          slug
        }
      }
    }
  }
`;

const StyledCollapse = styled.div`
  margin: 2rem 0;

  .Collapsible {
    display: block;
    width: 100%;
    max-width: 650px;
    padding: 0;
  }

  .Collapsible__trigger {
    display: block;
    width: 100%;
    max-width: 650px;
    box-sizing: border-box;
    color: ${(props) => props.theme.colors.primary};
    text-decoration: underline;
    font-weight: 900;
    // background-color: green;
    position: relative;

    &:after {
      display: none;
    }

    &.is-open {
      color: ${(props) => props.theme.colors.customRed};
    }

    &:hover {
      cursor: pointer;
    }
  }

  .container {
    margin-top: 14px;
    width: 100%;
    max-width: 650px;
    .Collapsible {
      width: 100%;
      max-width: 650px;
      padding-left: 14px;
      box-sizing: border-box;
      border: 1px solid ${(props) => props.theme.colors.customGray};
      border-radius: 5px;
    }
    .Collapsible__trigger {
      width: 100%;
      max-width: 650px;
      box-sizing: border-box;
      padding: 14px 13px 14px 0;
      color: ${(props) => props.theme.colors.text};

      &:after {
        width: 14px;
        height: 8px;
        display: block;
        position: absolute;
        right: 13px;
        top: 40%;
        transform: translateY(-50%);
      }

      &.is-open {
        &:after {
          content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45OTUiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDEzLjk5NSA4Ij4KICAgIDxnPgogICAgICAgIDxnPgogICAgICAgICAgICA8cGF0aCBmaWxsPSIjZTczNDJjIiBkPSJNNy45OTMgNy4xMTVhMSAxIDAgMCAxLS4yODkuNTkzbC02IDZBMSAxIDAgMSAxIC4zIDEyLjI4M0w1LjU4MSA3IC4zIDEuNzEyQTEgMSAwIDAgMSAxLjcwOC4yOTJsNiA2YTEgMSAwIDAgMSAuMjg1LjgyM3oiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDAgOCkgcm90YXRlKC05MCkgdHJhbnNsYXRlKDApIi8+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K");
        }
      }

      &.is-closed {
        &:after {
          content: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMy45OTUiIGhlaWdodD0iOCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBmaWxsPSIjMjRkZGFmIiBkPSJNNy4xMTQgNy45OTNhMSAxIDAgMCAwIC41OTQtLjI5M2w2LTZhMSAxIDAgMCAwLS40NDQtMS43MDcgMSAxIDAgMCAwLS45ODEuMzA3TDYuOTk1IDUuNTgxIDEuNzEyLjNBMSAxIDAgMCAwIC4yOTUgMS43MDhsNiA2YTEgMSAwIDAgMCAuODE5LjI4NXoiLz48L3N2Zz4=");
        }
      }
    }
  }
`;

const StyledDepartment = styled.div`
  columns: 2 auto;
  padding: 14px 13px;
  color: ${(props) => props.theme.colors.primary};
  line-height: 24px;
  a {
    color: ${(props) => props.theme.colors.primary};
  }
`;
const options = [
  { value: "plomberie", label: "Plomberie" },
  { value: "electricite", label: "Électricité" },
  { value: "gaz", label: "Gaz" },
  { value: "electromenager", label: "Électroménager" },
];

const defaultValue = { value: "plomberie", label: "Plomberie" };

const IndexPage = ({ data }) => {
  const { state, dispatch } = useContext(AppContext);
  const [pageUrl, setPageUrl] = useState("");

  async function getToken() {
    try {
      const tenantUrl =
        "https://api.homeserve.fr/ondemand/tenant/authenticateApp/hsv";
      const token = await axios.get(`${tenantUrl}`);
      if (token.status === 200) {
        dispatch({ type: "set_app_context", payload: { token: token.data } });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Error fetching token:", error);
    }
  }

  useEffect(async () => {
    if (typeof window !== "undefined") {
      setPageUrl(window.location.href);
    }
    if (state.token.access_token === "") {
      getToken();
    }
  }, []);

  return (
    <Layout>
      <Helmet>
        <title>
          Réparations et dépannages à domicile partout en France par MesBonsPros
        </title>
        <meta
          name="description"
          content="Vous êtes à la recherche d'un professionnel en plomberie, électricité, serrurerie, gaz ou électroménager pour un dépannage à domicile ? Faites confiance à MesBonsPros par HomeServe !"
          data-react-helmet="true"
        />
        <link rel="canonical" href={pageUrl} />
      </Helmet>
      <Hero title="<h1>Trouver un professionnel MesBonsPros en France</h1>">
        <FormHero options={options} defaultValue={defaultValue} />
      </Hero>
      <StickyBar defaultValue={defaultValue} options={options} />
      <ListCheck />
      <TextImg
        title={
          <h2>
            Vous avez besoin d’un dépannage à domicile quelque part en France ?
          </h2>
        }
        text="<p>MesBonsPros s’appuie sur le réseau d’HomeServe qui regroupe plus de 4 000 professionnels disponibles dans toute la France métropolitaine. Fort de cet important maillage national, nous réalisons environ 1 intervention toutes les 3 minutes partout en France.</p>
          <p>Nos domaines d’interventions sont les suivants : Plomberie, Electricité, Gaz, Serrurerie et Électroménager. Nous pouvons vous venir en aide pour des dépannages type : réparation d’une fuite d’eau, rétablissement du courant suite à une panne électrique… mais aussi pour des petits travaux comme la création d’une nouvelle arrivée d’eau ou encore la pose de radiateurs.</p>
          <p>Depuis plus de 20 ans maintenant, nous avons eu le temps de déployer un réseau important et une relation de confiance forte avec nos professionnels. Nous sommes en mesure d’intervenir sur l’ensemble du territoire national. Retrouvez ci-dessous à titre indicatif, une liste des villes dans lesquelles nous intervenons mais gardez bien à l’esprit que celle-ci n’est pas exhaustive.</p>
          <p>Si votre ville ne se trouve pas dans cette liste, n’hésitez pas à nous contacter au 01 70 82 17 82, nos conseillers vous apporteront une réponse adaptée.</p>"
        image={PlomberieImg}
      />
      <Separator />
      <Wrapper>
        <StyledCollapse>
          {data.allRegionsJson.edges.map((region, index) => {
            const code = region.node.code;
            return (
              <div key={index} code={region.node.code}>
                <div>
                  <Title>
                    <h2 style={{ color: "black" }}>
                      MesBonsPros en {region.node.name}
                    </h2>
                  </Title>
                </div>
                <Collapsible
                  trigger="> Afficher les départements"
                  triggerWhenOpen="Réduire"
                >
                  {data.allDepartmentsJson.edges
                    .filter((department) =>
                      department.node.region_code.includes(code),
                    )
                    .map((department, index) => {
                      const cp = department.node.code;
                      return (
                        <div className="container" key={index}>
                          <Collapsible
                            trigger={`${department.node.name} (${cp})`}
                          >
                            <StyledDepartment>
                              {data.allCitiesJson.edges
                                .filter((city) =>
                                  city.node.department_code.includes(cp),
                                )
                                .map((city, index) => {
                                  return (
                                    <div key={index}>
                                      -{" "}
                                      <Link
                                        to={`/${_.kebabCase(city.node.slug)}/`}
                                      >
                                        {city.node.name} {city.node.insee_code}
                                      </Link>
                                    </div>
                                  );
                                })}
                            </StyledDepartment>
                          </Collapsible>
                        </div>
                      );
                    })}
                </Collapsible>
              </div>
            );
          })}
        </StyledCollapse>
      </Wrapper>
      <Separator />
      <Avis />
    </Layout>
  );
};

export default IndexPage;
